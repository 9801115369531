import * as React from 'react';
import { Link } from 'gatsby';
import Button from '@material-ui/core/Button';

import SEO from '../components/Seo';

const NotFoundPage = (): React.ReactNode => (
  <>
    <SEO title="404: Not found" />

    <h1>404: Page not found</h1>

    <Button color="primary" component={Link} to="/">
      Go back to the homepage
    </Button>
  </>
);

export default NotFoundPage;
